import { Box, Link, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Experience } from '.';
import { SocialLinks } from './constants/socials';

type IntroProps = {
	name: string,
	birthday: string
}

export const Introduction: React.FC<IntroProps> = ({ name, birthday }) => {
	const calculatedAge = () => {
		return moment().diff(birthday, 'years');
	}

	const socialLinks = SocialLinks.map(socialLink => {
		return (
			<div key={socialLink.title} className="social-link">
				<Link
					color="inherit"
					href={socialLink.url}
					title={socialLink.title}
					target="_blank"
					rel="noreferrer"
				>
					{<socialLink.icon />}
				</Link>
			</div>
		);
	})

	return (
		<Box margin="2vh auto" px={{ xs: 2, sm: 2, md: 10, lg: 18, xl: 45 }}>
			<Typography component="h3" variant="h3" textAlign="left">
				Tjena 👋🏼
			</Typography>
			<Typography component="h5" variant="h5" textAlign="left" padding="10px">
				{name} är mitt namn. Jag är en {calculatedAge()}-årig Systemutvecklare från Norrköping som har utveckling som intresse, med stort fokus på språk såsom C#, JavaScript och TypeScript, och ramverk som React.js och Angular. Jag är alltid öppen för ny teknik och nya verktyg och ser alltid fram emot att lära mig mer!
			</Typography>
			<Experience />
			<Box margin="5vh auto auto auto">
				<Typography component="h5" variant="h5" textAlign="center">
					Jag finns även på 👇🏻
				</Typography>
				<Typography component="div" display="flex" flexDirection="row" justifyContent="center">
					{socialLinks}
				</Typography>
			</Box>
		</Box>
	);
}
