import { GitHub, Instagram, LinkedIn, SvgIconComponent } from '@mui/icons-material';

type SocialLink = {
	title: string,
	url: string,
	icon: SvgIconComponent
}

export const SocialLinks: Array<SocialLink> = [
	{
		title: 'my github profile',
		url: 'https://github.com/macksterino',
		icon: GitHub
	},
	{
		title: 'my linkedin profile',
		url: 'https://www.linkedin.com/in/marcusgarpehällwiklund/',
		icon: LinkedIn
	},
	{
		title: 'my instagram profile',
		url: 'https://www.instagram.com/marcusgw/',
		icon: Instagram
	},
]
