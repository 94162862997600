import { ExpandMore, SvgIconComponent } from '@mui/icons-material';

type Experience = {
	id: number,
	title: string,
	company: string,
	dateFrom: string,
	dateTo: string,
	description: string,
	icon: SvgIconComponent
}

export const PastExperiences: Array<Experience> = [
	{
		id: 0,
		title: "Integrationsutvecklare",
		company: "Navcite",
		dateFrom: "2022-01-10",
		dateTo: "Nu",
		description: "Affärsutveckling med fokus på bland annat C# i backend med iCore som integrationsplattform och JavaScript, TypeScript och Angular för frontend utveckling.",
		icon: ExpandMore
	},
	{
		id: 1,
		title: "Systemutvecklare genom LIA/praktik/examensarbete",
		company: "Norrköpings kommun",
		dateFrom: "2020-02",
		dateTo: "2021-05",
		description: "Verksamhetsutveckling med fokus på mindre, självständiga uppdrag. Jag arbetade väldigt mycket med TypeScript och Node.js för integration men arbetade dessutom med tex. PHP och Powershell.",
		icon: ExpandMore
	}
];
