import { Box, createTheme, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Introduction, ThemeHandler } from './components';

function App() {
	const [darkMode, setDarkMode] = React.useState<boolean>(false);
	const theme = createTheme(
		{
			palette: {
				mode: darkMode ? 'dark' : 'light'
			}
		}
	);

	console.log("Bra försök 🤠");

	return (
		<ThemeProvider theme={theme}>
			<ScopedCssBaseline>
				<Box height="100vh">
					<ThemeHandler darkMode={darkMode} setDarkMode={setDarkMode} />
					<Introduction name="Marcus Garpehäll Wiklund" birthday="1998-02-23" />
				</Box>
			</ScopedCssBaseline>
		</ThemeProvider>
	);
}

export default App;
