import { Box, Switch } from '@mui/material';
import React from 'react';

type ThemeProps = {
	darkMode: boolean,
	setDarkMode: (darkMode: boolean) => void
}

export const ThemeHandler: React.FC<ThemeProps> = ({ darkMode, setDarkMode }) => {
	const handleModeChange = () => {
		setDarkMode(!darkMode);
	}

	return (
		<Box display="flex" justifyContent="flex-end" padding="10px">
			<Switch
				checked={darkMode}
				onChange={handleModeChange}
				name="dark mode"
				color="default"
				title={`switch to ${(darkMode === false) ? 'dark mode' : 'light mode'}`}
			/>
		</Box>
	)
}
