import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import React from "react";
import { PastExperiences } from "./constants/experiences";

export const Experience: React.FC<any> = () => {
	const experiences = PastExperiences.map(experience => {
		return (
			<Accordion prefix="test" key={experience.id} sx={{ margin: "5px 0px" }}>
				<AccordionSummary expandIcon={<experience.icon />} aria-controls={"panella-content"} id="experience-header" color={"primary"}>
					<Box display={"flex"} flexDirection={"column"}>
						<Typography component={"h5"} variant={"h5"}>{experience.company}</Typography>
						<Typography component={"sub"} variant={"subtitle1"}>{experience.title}</Typography>
						<Typography component={"sub"} variant={"subtitle2"}>{experience.dateFrom} - {experience.dateTo}</Typography>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>{experience.description}</Typography>
				</AccordionDetails>
			</Accordion>
		);
	});

	return (
		<Box margin={"5vh auto"}>
			<Typography component={"h4"} variant={"h4"}>
				Arbetslivserfarenhet
			</Typography>
			<Box>
				{experiences}
			</Box>
		</Box>
	);
}
